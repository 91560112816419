import { AzureAppConfiguration, load } from '@azure/app-configuration-provider'
import { ConfigurationMapFeatureFlagProvider, FeatureManager } from '@microsoft/feature-management'
import config from 'src/config/config'
import { FeatureFlag } from 'src/config/featureFlags'

export class FeatureFlagService {
    private static settings: AzureAppConfiguration
    private static featureManger: FeatureManager

    private static async init() {
        this.settings = await load(config.AZURE_APPCONFIG_CONNECTION_STRING ?? '', {
            featureFlagOptions: {
                enabled: true,
                selectors: [
                    {
                        keyFilter: '*',
                    },
                ],
                refresh: {
                    enabled: true,
                },
            },
        })

        const ffProvider = new ConfigurationMapFeatureFlagProvider(this.settings)
        this.featureManger = new FeatureManager(ffProvider)
    }

    public static async refresh(): Promise<void> {
        if (!this.featureManger) {
            await this.init()
        }
        await this.settings?.refresh()
    }

    public static async isEnabled(feature: FeatureFlag): Promise<boolean> {
        try {
            if (!this.featureManger) {
                await this.init()
            }
            return await this.featureManger.isEnabled(feature.toString())
        } catch (ex) {
            console.error(ex)
        }
        return false
    }
}
