export const appRoutesWithoutTopNavBar = {
    displayToS: { key: 'displayToS', path: '/contracts/displayToS/[contractId]/[templateSubType]' },
}

export const appRoutes = {
    homepage: { key: '', path: '/' },
    contracts: { key: 'contracts', path: '/contracts' },
    pvcontracts: { key: 'pvContracts', path: '/contracts/pv' },
    hpContracts: { key: 'hpContracts', path: '/contracts/hp' },
    products: { key: 'products', path: '/products' },
    spvs: { key: 'spvs', path: '/spvs' },
    templates: { key: 'templates', path: '/contracts/templates' },
    placeholders: { key: 'placeholders', path: '/placeholders' },
    textPlaceholders: { key: 'textPlaceHolders', path: '/placeholders/text' },
    textPlaceholdersEdit: { key: 'textPlaceholdersEdit', path: '/placeholders/text/edit' },
    imagePlaceholders: { key: 'imagePlaceholders', path: '/placeholders/image' },
    imagePlaceholdersEdit: { key: 'imagePlaceholdersEdit', path: '/placeholders/image/edit' },
    lineItemPlaceholders: { key: 'lineItemPlaceholders', path: '/placeholders/lineItem' },
    lineItemPlaceholdersEdit: { key: 'lineItemPlaceholdersEdit', path: '/placeholders/lineItem/edit' },
    handleContract: { key: 'handleContract', path: '/contracts/handleContract' },
    presentations: { key: 'presentations', path: '/presentations' },
    presentationGroups: { key: 'presentationGroups', path: '/presentations/groups' },
    presentationTemplates: { key: 'presentationTemplates', path: '/presentations/templates' },
    createPresentationGroup: { key: 'createPresentationGroup', path: '/presentations/groups/create' },
    presentationGroupsEdit: { key: 'presentationGroupsEdit', path: '/presentations/groups/edit' },
    conditions: { key: 'conditions', path: '/placeholders/conditions' },
    conditionsCreate: { key: 'conditionsCreate', path: '/placeholders/conditions/create' },
    conditionsEdit: { key: 'conditionsEdit', path: '/placeholders/conditions/edit' },
    templateConditions: { key: 'templateConditions', path: '/placeholders/templateConditions' },
    ...appRoutesWithoutTopNavBar,
}
