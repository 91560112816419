import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { FeatureFlag } from 'src/config/featureFlags'
import { appRoutes } from 'src/constants/appRoutes'
import { featureFlags } from 'src/constants/featureFlags'
import { FeatureFlagService } from 'src/services/featureFlagService'
import SubMenuItems from './SubMenuItems'
import { SubMenuType, SubRoute } from './SubMenuItems.types'

const SubMenu = ({ selectedKeys }: SubMenuType) => {
    const [isFeatureFlagsEnabled, setIsFeatureFlagsEnabled] = useState(false)
    const [isConditionsEnabled, setIsConditionsEnabled] = useState(false)
    const [isHPContractsEnabled, setIsHPContractsEnabled] = useState(false)

    useEffect(() => {
        async function fetchMenus() {
            //TODO- this if for backward compatability - remove it after migrating to new feature flags manager
            setIsFeatureFlagsEnabled(await FeatureFlagService.isEnabled(FeatureFlag.FEATURE_FLAGS_ENABLED))

            setIsConditionsEnabled(await FeatureFlagService.isEnabled(FeatureFlag.MENU_CONDITIONS_SUBMENU_ENABLED))
            setIsHPContractsEnabled(await FeatureFlagService.isEnabled(FeatureFlag.HP_CONTRACTS_ENABLED))
        }
        fetchMenus()
    })

    const subRoutes: SubRoute[] = [
        {
            pathname: appRoutes.placeholders.path,
            subpaths: [
                { href: appRoutes.textPlaceholders.path, displayLabel: 'Text', key: appRoutes.textPlaceholders.key },
                { href: appRoutes.imagePlaceholders.path, displayLabel: 'Image', key: appRoutes.imagePlaceholders.key },
                {
                    href: appRoutes.lineItemPlaceholders.path,
                    displayLabel: 'LineItem',
                    key: appRoutes.lineItemPlaceholders.key,
                },
                ...((isFeatureFlagsEnabled ? isConditionsEnabled : featureFlags.MENU_CONDITIONS_SUBMENU_ENABLED)
                    ? [
                          {
                              href: appRoutes.conditions.path,
                              displayLabel: 'Conditions',
                              key: appRoutes.conditions.key,
                          },
                      ]
                    : []),
            ],
        },
        {
            pathname: appRoutes.contracts.path,
            subpaths: [
                { href: appRoutes.templates.path, displayLabel: 'Contract templates', key: appRoutes.templates.key },
                {
                    href: appRoutes.pvcontracts.path,
                    displayLabel: 'PV Contract groups',
                    key: appRoutes.pvcontracts.key,
                },
                ...((isFeatureFlagsEnabled ? isHPContractsEnabled : featureFlags.HP_CONTRACTS_SUBMENU_ENABLED)
                    ? [
                          {
                              href: appRoutes.hpContracts.path,
                              displayLabel: 'HP Contract groups',
                              key: appRoutes.hpContracts.key,
                          },
                      ]
                    : []),
            ],
        },
        {
            pathname: appRoutes.presentations.path,
            subpaths: [
                {
                    href: appRoutes.presentationTemplates.path,
                    displayLabel: 'Presentation templates',
                    key: appRoutes.presentationTemplates.key,
                },
                {
                    href: appRoutes.presentationGroups.path,
                    displayLabel: 'Presentation groups',
                    key: appRoutes.presentationGroups.key,
                },
            ],
        },
    ]
    const router = useRouter()
    const { asPath: routerPathName } = router
    const subRoute = subRoutes.find((subroute) => routerPathName.startsWith(subroute.pathname))

    if (!subRoute) {
        return null
    }

    return <SubMenuItems pathname={subRoute.pathname} menuItems={subRoute.subpaths} selectedKeys={selectedKeys} />
}

export default SubMenu
