import { Button, Menu, MenuProps } from 'antd'
import { signIn, signOut, useSession } from 'next-auth/react'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useId, useMemo, useState } from 'react'
import { FeatureFlag } from 'src/config/featureFlags'
import { appRoutes } from 'src/constants/appRoutes'
import { featureFlags } from 'src/constants/featureFlags'
import { FeatureFlagService } from 'src/services/featureFlagService'
import SubMenu from './SubMenu'
import styles from './TopNavBar.module.css'

const accessTokenKey = 'accessToken'
const refreshAccessTokenError = 'RefreshAccessTokenError'

const TopNavBar = () => {
    const { data: session, status } = useSession()
    const loading = status === 'loading'
    const id = useId()
    const router = useRouter()
    const [isFeatureFlagsEnabled, setIsFeatureFlagsEnabled] = useState(false)
    const [isSubMenuEnabled, setIsSubMenuEnabled] = useState(false)
    const [isPlaceholdersEnabled, setIsPlaceholdersEnabled] = useState(false)

    useEffect(() => {
        if (session === undefined) {
            return
        }

        if (session === null) {
            localStorage.removeItem(accessTokenKey)
            handleSignIn()
            return
        }

        async function fetchMenus() {
            //TODO- this if for backward compatability - remove it after migrating to new feature flags manager
            setIsFeatureFlagsEnabled(await FeatureFlagService.isEnabled(FeatureFlag.FEATURE_FLAGS_ENABLED))

            setIsSubMenuEnabled(await FeatureFlagService.isEnabled(FeatureFlag.MENU_SUBMENU_ENABLED))
            setIsPlaceholdersEnabled(await FeatureFlagService.isEnabled(FeatureFlag.MENU_SUBMENU_ENABLED))
        }

        fetchMenus()

        localStorage.setItem(accessTokenKey, session.user.accessToken)
    }, [session])

    const handleSignIn = async () => {
        await signIn()
    }

    const handleSignOut = async () => {
        await signOut()
    }

    const menuItems: MenuProps['items'] = useMemo(() => {
        const items: MenuProps['items'] = []
        if (isFeatureFlagsEnabled ? isSubMenuEnabled : featureFlags.MENU_SUBMENU_ENABLED) {
            items.push({
                label: <Link href={appRoutes.pvcontracts.path}>Contracts</Link>,
                key: appRoutes.pvcontracts.key,
            })
        } else {
            items.push(
                {
                    label: <Link href={appRoutes.templates.path}>Templates</Link>,
                    key: appRoutes.templates.key,
                },
                {
                    label: <Link href={appRoutes.pvcontracts.path}>Contracts</Link>,
                    key: appRoutes.pvcontracts.key,
                }
            )
        }

        if (isFeatureFlagsEnabled ? isPlaceholdersEnabled : featureFlags.MENU_PLACEHOLDERS_ENABLED) {
            items.push({
                label: <Link href={appRoutes.placeholders.path}>Placeholders</Link>,
                key: appRoutes.placeholders.key,
            })
            items.push({
                label: <Link href={appRoutes.presentationTemplates.path}>Presentations</Link>,
                key: appRoutes.presentations.key,
            })
        }

        return items
    }, [])

    const getSelectedRouteForMainNav = () => {
        const parentRoutes = router.pathname.split('/')
        return parentRoutes.length > 1 ? [parentRoutes[1]] : []
    }

    const getSelectedRouteSubNav = () => {
        let route: keyof typeof appRoutes
        for (route in appRoutes) {
            if (appRoutes[route].path === router.pathname) {
                return [appRoutes[route].key]
            }
        }
        return []
    }

    if (session?.error === refreshAccessTokenError) {
        handleSignOut()
    }

    if (loading) {
        return <div>Loading...</div>
    }

    return (
        <header className={styles.headerMenuContainer}>
            <div className={styles.headerMainMenuContainer}>
                <div className={styles['logo-wrapper']}>
                    <Image src="/enpal-icon-dark.png" alt="logo" height={35} width={35} />
                </div>
                <span className={styles['header-title']}>DocHub</span>
                {session ? (
                    <>
                        <Menu
                            key={id}
                            theme="dark"
                            mode="horizontal"
                            className={styles['menuItems']}
                            selectedKeys={getSelectedRouteForMainNav()}
                            items={menuItems}
                        ></Menu>

                        <span className={styles['header-user']}>{session.user.email}</span>
                        <Button className={styles['button']} type="primary" onClick={handleSignOut}>
                            Log Out
                        </Button>
                    </>
                ) : (
                    <>
                        <Menu key={id} theme="light" mode="horizontal" className={styles['menuItems']}></Menu>
                        <Button className={styles['button']} type="primary" onClick={handleSignIn}>
                            Log In
                        </Button>
                    </>
                )}
            </div>
            {(isFeatureFlagsEnabled ? isSubMenuEnabled : featureFlags.MENU_SUBMENU_ENABLED) ? (
                <div className={styles.headerSubMenuContainer}>
                    {session && <SubMenu selectedKeys={getSelectedRouteSubNav()} />}
                </div>
            ) : (
                <></>
            )}
        </header>
    )
}

export { TopNavBar as default }
